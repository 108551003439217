<template>
  <div class="export-page padding-top-layout padding-right-layout list-menu">
    <div class="list-menu-title">
      <page-title :title="textTitle" />
    </div>
    <p class="list-menu--title">
      {{ $t('b_export_report.description_text_description')}}
    </p>
    <div class="listMenus-btn">
      <div class="title-btn">
        <span>{{ $t('b_export_report.label_output_method')}}</span>
        <div class="title-require">{{ $t('b_export_report.label_required')}}</div>
      </div>
      <div class="listMenu-btn mb-0">
        <div class="listMenu-btn-text">
          <v-select
            solo
            flat
            :items="methodsExport"
            v-model="dataExport.methodExport"
            :label="pleaseSelect"
            class="select-type list-menu--select select-item methods-export"
            :menu-props="{ contentClass: 'select-menu export-select' }"
          />
        </div>
      </div>
      <div class="listMenu-icon">
        <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
      </div>
      <div class="title-btn">
        <span>{{ $t('b_export_report.label_period')}}</span>
        <div class="title-require">{{ $t('b_export_report.label_required')}}</div>
      </div>
      <div class="listMenu-btn">
        <div class="listMenu-btn-text option-duration">
          <v-select
            solo
            flat
            :items="startMonthList"
            v-model="dataExport.monthStartAt"
            item-text="text"
            item-value="value"
            :label="chooseMonth"
            class="select-type list-menu--select select-item"
            :menu-props="{ contentClass: 'select-menu export-select' }"
          />
          <v-select
            solo
            flat
            :items="startYearList"
            v-model="dataExport.yearStartAt"
            item-text="text"
            item-value="value"
            :label="chooseYear"
            class="select-type list-menu--select select-item"
            :menu-props="{ contentClass: 'select-menu export-select' }"
          />
        </div>
        <div class="cross-line">|</div>
        <div class="listMenu-btn-text option-duration">
          <v-select
            solo
            flat
            :items="endMonthList"
            v-model="dataExport.monthEndAt"
            item-text="text"
            item-value="value"
            :label="chooseMonth"
            class="select-type list-menu--select select-item"
            :menu-props="{ contentClass: 'select-menu export-select' }"
          />
          <v-select
            solo
            flat
            :items="endYearList"
            v-model="dataExport.yearEndAt"
            item-text="text"
            item-value="value"
            :label="chooseYear"
            class="select-type list-menu--select select-item"
            :menu-props="{ contentClass: 'select-menu export-select' }"
          />
        </div>
      </div>
      <div class="listMenu-icon">
        <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
      </div>
      <div class="title-btn">
        <span>{{ $t('b_export_report.label_narrow_down')}}</span>
      </div>
      <div class="filter listMenu-btn filter-organization organization-pulldown">
        <export-pulldown :key="reRenderKey.organizational" :dataList="dataList.organizational" :selectedData.sync="dataExport.organizational" :itemCount="dataList.organizational.length - 1" :placeholder="placeholderOrganizational" :arrowDownBtn="true" />
      </div>
      <div class="filter filter-methods mt-4 select-corporation-btn">
        <export-pulldown :key="reRenderKey.companies" :dataList="dataList.companies" :selectedData.sync="dataExport.companies" :itemCount="dataList.companies.length - 1" :placeholder="placeholderCorporation" :searchPlacehoder="placeholderSearchCompany" :hasSearch="true" />
      </div>
      <div class="filter filter-methods mt-4 select-product-btn">
        <export-pulldown :key="reRenderKey.methods" :dataList="dataList.methods" :selectedData.sync="dataExport.methods" :itemCount="dataList.methods.length - 1" :placeholder="placeholderProduct" :searchPlacehoder="placeholderSearchProduct" :hasSearch="true" :isSelectProduct="true" type="listScopeCategories" />
      </div>
      <FormatExportText v-if="dataExport.methodExport === 2" :isMobile="true" />
    </div>
    <div class="list-menu-registration" :class="{'has-encoding': dataExport.methodExport === 2}">
      <common-button
        class="submit-register-btn"
        :label="btnOutputData"
        :disabled="validateData()"
        type="colored"
        @action="exportData()"
        v-ripple="false"
      />
      <FormatExportText v-if="dataExport.methodExport === 2" />
    </div>
    <div class="return__button-responsive">
      <div class="registration-responsive">
        <common-button
          class="submit-register-btn"
          :label="btnOutputData"
          :disabled="validateData()"
          type="colored"
          @action="exportData()"
          v-ripple="false"
        />
      </div>
    </div>
    <error-popup
      :dialog="errorDialog"
      :message="errorMessage"
      @submit="handleClose"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { ROUTES } from "@/router/constants";
import PageTitle from "@/components/pageTitle/pageTitle";
import CommonButton from "@/components/utils/button.vue";
import { getStartMonth } from "@/api/duration";
import { GET_ALL } from '@/constants/export-report';
import ExportPulldown from '@/components/pulldown/exportReport';
import { getListDetailBranch } from '@/api/registerData';
import { getProductMasterApi, getExportReport } from '@/api/product';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import moment from "moment";
import { calcYearFromStartMonthCustome, getMonthListCustome, prepareBranchData } from '@/utils/registerData';
import FormatExportText from '@/components/csvFormat/export-text';

export default {
  name: "ExportReportPage",
  components: {
    PageTitle,
    CommonButton,
    ExportPulldown,
    ErrorPopup,
    FormatExportText
  },
  data() {
    return {
      breadcrumb             : [
        {
          text    : this.$t('b_export_report.hyperlink_home'),
          disabled: false,
          href    : ROUTES.PRODUCTS
        },
        {
          text    : this.$t('b_export_report.label_report_output'),
          disabled: true,
          href    : ROUTES.EXPORT_REPORT
        }
      ],
      yearList: [],
      errorDialog                : false,
      dataExport: {
        methodExport: 1,
        organizational: [],
        companies: [],
        methods: [],
        monthStartAt: null,
        yearStartAt: null,
        monthEndAt: null,
        yearEndAt: null,
      },
      methodsExport: [
        { value: 1, text: this.$t('b_export_report.pulldown_unit_ghg_emissions') },
        { value: 2, text: this.$t('b_export_report.pulldown_unit_detail') },
      ],
      monthList: [],
      dataList: {
        organizational: [],
        companies: [],
        methods: [],
        companyBranchIds: [],
      },
      selectedDuration: null,
      selectedYearDuration: null,
      errorMessage: "",
      filterIndex: [],
      reRenderKey: {
        organizational: 0,
        companies: 0,
        methods: 0,
      },
      startMonth: null,
      startYearList: [],
      endYearList: [],
      startMonthList: [],
      endMonthList: [],
      branchData: {},
    };
  },
  computed: {
    textTitle() {
      return this.$t('b_export_report.title_report_output')
    },
    pleaseSelect() {
      return this.$t('b_export_report.placeholder_please_select')
    },
    chooseYear() {
      return this.$t('b_export_report.placeholder_select_year')
    },
    chooseMonth() {
      return this.$t('b_export_report.placeholder_select_month')
    },
    placeholderOrganizational() {
      return this.$t('b_export_report.placeholder_organizational_classification')
    },
    placeholderCorporation() {
      return this.$t('b_export_report.placeholder_select_a_corporation')
    },
    placeholderProduct() {
      return this.$t('b_export_report.placeholder_select_a_product')
    },
    btnOutputData() {
      return this.$t('b_export_report.button_export');
    },
    placeholderSearchProduct() {
      return this.$t('b_export_report.placeholder_search_for_products')
    },
    placeholderSearchCompany() {
      return this.$t('b_export_report.placeholder_search_for_corporations')
    }
  },
  async mounted() {
    this.updateBreadCrumb(this.breadcrumb);
    const monthRes = await getStartMonth(this.$store.state.userData.contractor)
    this.startMonth = monthRes.data.start_month
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    this.monthList = getMonthListCustome(currentYear, this.startMonth)
    this.yearList = calcYearFromStartMonthCustome(this.startMonth, currentYear, true)
    this.setDataYearAndMonth()
    // this.getMonthYearList();
    await Promise.all([this.handleGetBranchData(), this.getProductMasterList()]);
    this.defaultData();
  },
  watch: {
    'dataExport.organizational': {
      handler() {
        if (this.dataList.organizational.length <= 1) return
        this.addFilterIndex('organizational');
        if (this.dataExport.organizational.length === 0) {
          this.addFilterIndex('organizational', true);
        }
        this.updateFilter();
      },
      deep: true
    },
    'dataExport.companies': {
      handler() {
        if (this.dataList.companies.length <= 1) return
        this.addFilterIndex('company');
        if (this.dataExport.companies.length === 0) {
          this.addFilterIndex('company', true);
        }
        this.updateFilter();
      },
      deep: true
    },
    'dataExport.methodExport': {
      handler() {
        this.dataExport.methods.length = 0;
        this.reRenderKey.methods++;
        this.reRenderKey.organizational++;
        this.reRenderKey.organizational++;
        this.reRenderKey.companies++;
        this.reRenderKey.companies++;
      },
      deep: true
    },
    'dataExport.monthStartAt': {
      handler() {
        this.startYearList = calcYearFromStartMonthCustome(this.startMonth, this.dataExport.yearStartAt)
        const indexYear = this.startYearList.findIndex(item => item.value === this.dataExport.yearStartAt)
        if(indexYear < 0) {
          this.dataExport.yearStartAt = null
        }
        this.checkDuration('startAt');
      }
    },
    'dataExport.yearStartAt': {
      handler() {
        this.startMonthList = getMonthListCustome(this.dataExport.yearStartAt, this.startMonth)
        const indexMonth =  this.startMonthList.findIndex(item => item.value === this.dataExport.monthStartAt)
        if(indexMonth < 0) {
          this.dataExport.monthStartAt = null
        }
        this.checkDuration('startAt');
      }
    },
    'dataExport.monthEndAt': {
      handler() {
        this.endYearList = calcYearFromStartMonthCustome(this.startMonth, this.dataExport.yearEndAt)
        const indexYear = this.endYearList.findIndex(item => item.value === this.dataExport.yearEndAt)
        if(indexYear < 0) {
          this.dataExport.yearEndAt = null
        }
        this.checkDuration('endAt');
      }
    },
    'dataExport.yearEndAt': {
      handler() {
        this.endMonthList = getMonthListCustome(this.dataExport.yearEndAt, this.startMonth)
        const indexMonth =  this.endMonthList.findIndex(item => item.value === this.dataExport.monthEndAt)
        if(indexMonth < 0) {
          this.dataExport.monthEndAt = null
        }
        this.checkDuration('endAt');
      }
    },
  },
  methods: {
    ...mapActions("commonApp", ["updateBreadCrumb"]),
    async handleGetBranchData() {
      const response = await getListDetailBranch()
      this.branchData = prepareBranchData(response)
    },
    defaultData() {
      this.dataList.organizational.push({
        text: GET_ALL,
        hasCheckbox: true,
      })
      this.branchData.organizational_division.filter(item => item.status === true)?.map(item => {
        this.dataList.organizational.push({
          text: item.value,
          hasCheckbox: true,
          branch_ids: item.branch_ids,
          id: item.key,
        })
      });
      this.dataList.companies.push({
        text: GET_ALL,
        hasCheckbox: true,
      })
      this.branchData.company_name.filter(item => item.status === true)?.map(item => {
        this.dataList.companies.push({
          text: item.value,
          hasCheckbox: true,
        })
      });
      this.branchData.companyBranchIds?.map(item => {
        this.dataList.companyBranchIds.push({
          organizational_division: item.organizational_division,
          company_name: item.company_name,
          branch_ids: item.branch_ids,
          organizational_division_id: item.organizational_division_id
        })
      });
      this.reRenderKey.organizational++;
      this.reRenderKey.companies++;
    },
    gotoListRegistered(){
      return this.$router.push({
        path: `${ROUTES.EMISSIONS}`
      });
    },
    formatDuration(monthStart, yearStart) {
      return moment(`${monthStart} ${yearStart}`, 'MM YYYY').format('YYYY-MM');
    },
    async exportData() {
      if (!this.checkDuration()) return;
      let payload = {
        contractor_id : this.$store.state.userData.contractor,
        start_at : this.formatDuration(this.dataExport.monthStartAt, this.dataExport.yearStartAt),
        end_at : this.formatDuration(this.dataExport.monthEndAt, this.dataExport.yearEndAt),
        organizational_division_branch_ids : [],
        company_name_branch_ids: [],
        list_product_master_ids : [],
      }
      payload.list_product_master_ids = this.dataExport.methods.map(item => {
        const productId = this.dataList.methods.find(product => product.text === item);
        return productId.id;
      })
      if (this.dataExport.companies.length === 0 && this.dataExport.organizational.length !== 0) {
        this.dataExport.organizational.map(item => {
          const branchIds = this.dataList.organizational.find(organi => organi.text === item);
          payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(branchIds?.branch_ids);
        })
      } else if (this.dataExport.organizational.length === 0 && this.dataExport.companies.length !== 0) {
        this.dataExport.companies.map(item => {
          this.dataList.companyBranchIds.filter(company => company.company_name === item)
          .map(branchId => {
            payload.company_name_branch_ids = payload.company_name_branch_ids.concat(branchId?.branch_ids);
          })
        }) 
      } else {
        if (this.filterIndex.length > 0) {
          if (this.filterIndex[0] === 'organizational') {
            let newArray = [...this.dataList.companyBranchIds];
            let newOrgari = [];
            for (let i = newArray.length - 1; i >= 0; i--) {
              if (this.dataExport.companies.includes(newArray[i].company_name) && this.dataExport.organizational.includes(newArray[i].organizational_division)) {
                newOrgari.push(newArray[i].organizational_division);
                payload.company_name_branch_ids = payload.company_name_branch_ids.concat(newArray[i].branch_ids);
                payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(newArray[i].branch_ids);
                newArray.splice(i, 1);
              } else if (!this.dataExport.organizational.includes(newArray[i].organizational_division)) {
                newArray.splice(i, 1);
              }
            }
            newArray.map(item => {
              if (!newOrgari.includes(item.organizational_division)) {
                payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(item.branch_ids);
              }
            })
          } else {
            let newArray = [...this.dataList.companyBranchIds];
            let newCompaies = [];
            for (let i = newArray.length - 1; i >= 0; i--) {
              if (this.dataExport.companies.includes(newArray[i].company_name) && this.dataExport.organizational.includes(newArray[i].organizational_division)) {
                newCompaies.push(newArray[i].company_name);
                payload.company_name_branch_ids = payload.company_name_branch_ids.concat(newArray[i].branch_ids);
                payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(newArray[i].branch_ids);
                newArray.splice(i, 1);
              } else if (!this.dataExport.companies.includes(newArray[i].company_name)) {
                newArray.splice(i, 1);
              }
            }
            newArray.map(item => {
              if (!newCompaies.includes(item.company_name)) {
                payload.company_name_branch_ids = payload.company_name_branch_ids.concat(item.branch_ids);
              }
            })
          }
        }
      }
      // let urlPayload = '?';
      // const arrayKey = ['organizational_division_branch_ids', 'company_name_branch_ids', 'list_product_master_ids'];
      // for (let key in payload) {
      //   if (arrayKey.includes(key)) {
      //     if (payload[key].length) {
      //       if (key === 'list_product_master_ids') {
      //         urlPayload += `${key}=[${payload[key]}]`;
      //       } else {
      //         urlPayload += `${key}=[${payload[key]}]&`;
      //       }
      //     }
      //   } else {
      //     urlPayload += `${key}=${payload[key]}&`;
      //   }
      // }
      // if (urlPayload.charAt(urlPayload.length - 1) === '&') { // Remove & if position is last string
      //   urlPayload = urlPayload.slice(0, -1);
      // }
      const isCsv = this.dataExport.methodExport === 1 ? false : true;
      await getExportReport(payload, isCsv).then(res => {
        this.getCsvExport(res, isCsv);
      }).catch(error => {
        console.log(error);
      });
    },
    getCsvExport(content, isCsv) {
      let fullDate  = moment().format("YYYYMMDDHHmmss");
      let excelName = `Product_report_${fullDate}.xlsx`;
      let url  = window.URL.createObjectURL(
        new Blob([content], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
      );
      if (isCsv) {
        excelName = `raw_report_${fullDate}.csv`;
        url = window.URL.createObjectURL(new Blob([content]))
      }
      const link = document.createElement("a");
      link.href  = url;
      link.setAttribute("download", excelName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getSettingIcon(image) {
      return require(`@/assets/icons/${image}`);
    },
    async getProductMasterList() {
      await getProductMasterApi()
        .then((response) => {
          this.dataList.methods.push({
            text: GET_ALL,
            id: null,
            hasCheckbox: true,
          })
          response.data.forEach(item => {
            this.dataList.methods.push({
              text: item.name,
              id: item.id,
              hasCheckbox: true,
            })
          });
        })
        .catch(() => {
        });
    },
    openScopePopup() {
      this.errorDialog = true;
    },
    handleClose() {
      this.errorDialog = false;
    },
    validateData() {
      if (this.dataExport.monthStartAt === null || this.dataExport.yearStartAt === null || this.dataExport.monthEndAt === null || this.dataExport.yearEndAt === null) {
        return true
      }
      return false
    },

    checkDuration(isStart = '') {
      if(this.dataExport.monthStartAt !== null && this.dataExport.yearStartAt !== null && this.dataExport.monthEndAt !== null && this.dataExport.yearEndAt !== null) {
        const monthStart = moment(this.dataExport.monthStartAt, 'MM').format('MM');
        const monthEnd = moment(this.dataExport.monthEndAt, 'MM').format('MM');
        const yearStart = moment(this.dataExport.yearStartAt, 'YYYY').format('YYYY');
        const yearEnd = moment(this.dataExport.yearEndAt, 'YYYY').format('YYYY');
        const dateStart = moment(`${monthStart} ${yearStart}`, 'MM YYYY');
        const dateEnd = moment(`${monthEnd} ${yearEnd}`, 'MM YYYY');

        if (dateStart.isAfter(dateEnd)) {
          this.errorMessage = isStart === 'startAt' ? this.$t('popup.popup_month_before_end_date') : this.$t('popup.popup_month_after_start_date');
          this.openScopePopup();
          return false;
        }
        return true;
      }
      return false;
    },
    addFilterIndex(type, isRemove = false) {
      const existIndex = this.filterIndex.findIndex((item) => {
        return item === type;
      });
      if (isRemove) {
        if (this.filterIndex[0] === type) {
          this.dataExport.companies.length = 0;
          this.dataExport.organizational.length = 0;
          this.filterIndex.length = 0;
          this.dataList.companyBranchIds.map(item => {
            this.addOrganiToList(item);
            this.addCompanyToList(item);
          })
          this.organiListSort();
          type === 'organizational' ? this.reRenderKey.companies++ : this.reRenderKey.organizational++;
        } else {
          this.filterIndex.splice(existIndex, 1);
        }
      }
      if (existIndex < 0) {
        this.filterIndex.push(type);
      }
    },
    updateFilter() {
      if (this.filterIndex.length === 0) {
        this.dataList.companyBranchIds.map(item => {
          this.addOrganiToList(item);
          this.addCompanyToList(item);
        })
        this.organiListSort();
      } else {
        if (this.filterIndex[0] === 'organizational') {
          this.dataList.companies.length = 0;
          this.dataList.companies.push({
            text: GET_ALL,
            hasCheckbox: true,
          })
          this.dataExport.organizational.map(organi => {
            this.dataList.companyBranchIds.filter(companyItem => companyItem.organizational_division === organi)
            .map(item => {
              this.addCompanyToList(item);
            })
          })
          const companies = this.dataList.companies.map(company => company.text);
          this.dataExport.companies.map((item, index) => {
            if (!companies.includes(item)) {
              this.dataExport.companies.splice(index, 1);
            }
          })
        } else { // this.filterIndex[0] === 'company'
          this.dataList.organizational.length = 0;
          this.dataList.organizational.push({
            text: GET_ALL,
            hasCheckbox: true,
          })
          this.dataExport.companies.map(company => {
            this.dataList.companyBranchIds.filter(organiItem => organiItem.company_name === company)
            .map(item => {
              this.addOrganiToList(item);
            })
          })
          const organiDivisions = this.dataList.organizational.map(organi => organi.text);
          this.dataExport.organizational.map((item, index ) => {
            if (!organiDivisions.includes(item)) {
              this.dataExport.organizational.splice(index, 1);
            }
          })
          this.organiListSort();
        }
      }
    },
    addOrganiToList(item) {
      const existIndex = this.dataList.organizational.findIndex(organi => organi.text === item.organizational_division);
      if (existIndex < 0) {
        this.dataList.organizational.push({
          text: item.organizational_division,
          hasCheckbox: true,
          branch_ids: item.branch_ids,
          id: item.organizational_division_id,
        })
      }
    },
    addCompanyToList(item) {
      const existIndex = this.dataList.companies.findIndex(company => company.text === item.company_name);
      if (existIndex < 0) {
        this.dataList.companies.push({
          text: item.company_name,
          hasCheckbox: true,
        })
      }
    },
    organiListSort() {
      this.dataList.organizational.sort((a, b) => {
        return a.id - b.id;
      })
    },
    setDataYearAndMonth() {
      //startYearList, endYearList
      this.startYearList = calcYearFromStartMonthCustome(this.startMonth, 2017)
      this.endYearList = [ ...this.startYearList ]
      this.startMonthList = this.endMonthList = getMonthListCustome(this.dataExport.yearStartAt, this.startMonth)
    },
  }
};
</script>
<style lang="scss">
.select-type .v-input__slot {
  background: #f7f7f2 !important;
}
.list-menu {
  .select-method {
    &.disabled {
      .v-input__slot {
      background: #E4E6E7 !important;
      input {
        background: unset !important;
      }
    }
    }
  }
}

.listMenu-btn-text {
  .v-text-field.v-text-field--solo .v-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoMid;
  }
}
.v-list-item .v-list-item__title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: $monoBlack;
}
.listMenu-scope .v-btn__content {
  color: $monoMid;
  height: max-content;
  max-width: 100%
}
.listMenu-scope.has-scope  .v-btn__content {
  color: $monoBlack;
}
.listMenu-scope .v-btn__content div{
  word-break: break-word;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-type.select-item .v-select__selection {
  color: $monoBlack;
}
</style>
<style lang="scss" scoped>
.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }
}
.export-page {
  .list-menu-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;

    width: 100%;
    // height: 40px;
    .return__header {
      display: none;
    }
  }

  .list-menu--title {
    width: 100%;
    font-family: 'Source Han Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    /* identical to box height, or 187% */
    margin-bottom: 48px;
    letter-spacing: 0.05em;

    /* Mono/Black */

    color: $monoBlack;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .listMenus-btn {
    display: flex;
    flex-flow: column;
    padding: 15px 20px;
    /* Background/Light */
    background: #e3eded;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .listMenu-icon {
      justify-content: center;
      align-items: center;
      display: flex;
      line-height: 24px;
      padding: 24px 0px;
    }

    .listMenu-btn {
      text-decoration: none;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 10px;
      flex-direction: column;
      // padding: 8px 8px 8px 12px;
      .listMenu-btn-text {
        width: 100%;
        align-items: center;

        .v-select {
          width: 100%;
        }
      }
      .cross-line {
        margin: 16px 0;
        text-align: center;
        color: $monoMid;
        font-size: 15px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.75px;
        height: 28px;
      }
    }

    .listMenu-scope {
      text-transform: capitalize;
      color: $monoMid;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.03em;
      margin-top: 10px;
      width: 100%;
      height: max-content !important;
      &.has-scope {
        color: $monoBlack;
      }
      &:hover {
        color: $monoWhite;
      }
    }
    .arrow-svg {
      width: 36px;
      height: 48px;
    }

    .title-btn {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0px;
      margin: 0;
      gap: 16px;
      width: 100%;
      height: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      .title-require {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 4px 2px;
        width: auto;
        height: 20px;
        margin-top: 8px;

        background: $goldMid;
        border-radius: 4px;
        color: white;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
      }
    }
    .filter {
      max-width: 100%;
    }
    .organization-pulldown {
        ::v-deep .pulldown-wrapper #dropdownInputText {
          box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
          transition: 0.1s ease-out;
          &:hover {
            background: $monoWhite;
            box-shadow: 0px 0.18115200102329254px 0.6340319514274597px 0px rgba(160, 181, 186, 0.48), 0px 0.5008620619773865px 1.7530173063278198px 0px rgba(160, 181, 186, 0.31), 0px 1.2058829069137573px 4.220590114593506px 0px rgba(160, 181, 186, 0.24), 0px 4px 14px 0px rgba(160, 181, 186, 0.17);
          }
          &.focus-pull-down {
            &:hover {
              background: $monoOffWhite;
            }
          }
        }
        ::v-deep .pulldown-wrapper.disabled {
          #dropdownInputText {
            &:hover {
              background: $monoLight;
              box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
              }
              .placeholder,.input-text {
                color: $monoMid;
              }
             &.focus-pull-down {
              border: none;
            }
          }
        }
      }
      .select-corporation-btn {
        ::v-deep .pulldown-wrapper #dropdownInputText {
          box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
          transition: 0.1s ease-out;
          &:hover {
            background: $goldLight;
            box-shadow: 0px 0.18115200102329254px 0.6340319514274597px 0px rgba(160, 181, 186, 0.48), 0px 0.5008620619773865px 1.7530173063278198px 0px rgba(160, 181, 186, 0.31), 0px 1.2058829069137573px 4.220590114593506px 0px rgba(160, 181, 186, 0.24), 0px 4px 14px 0px rgba(160, 181, 186, 0.17);
            .placeholder,.input-text {
              color: $monoWhite;
            }
          }
        }
        ::v-deep .pulldown-wrapper.disabled {
          #dropdownInputText {
            &:hover {
              background: $monoLight;
              box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
              }
              .placeholder,.input-text {
                color: $monoMid;
              }
             &.focus-pull-down {
              border: none;
            }
          }
        }
      }
      .select-product-btn {
        ::v-deep .pulldown-wrapper #dropdownInputText {
          box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
          transition: 0.1s ease-out;
          &:hover {
            background: $goldLight;
            box-shadow: 0px 0.18115200102329254px 0.6340319514274597px 0px rgba(160, 181, 186, 0.48), 0px 0.5008620619773865px 1.7530173063278198px 0px rgba(160, 181, 186, 0.31), 0px 1.2058829069137573px 4.220590114593506px 0px rgba(160, 181, 186, 0.24), 0px 4px 14px 0px rgba(160, 181, 186, 0.17);
            .placeholder,.input-text {
              color: $monoWhite;
            }
          }
        }
        ::v-deep .pulldown-wrapper.disabled {
          #dropdownInputText {
            &:hover {
              background: $monoLight;
              box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
              }
              .placeholder,.input-text {
                color: $monoMid;
              }
             &.focus-pull-down {
              border: none;
            }
          }
        }
      }
  }

  .page-title {
    margin-bottom: 50px;
  }

  .v-select.v-text-field input {
    width: 496px;
    height: 40px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;

    .v-input__slot {
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-radius: 4px;
      background: $monoOffWhite !important;
    }
  }

  .divider {
    width: 100%;
  }

  .list-menu-registration {
    display: none;
  }
  .title-btn-select-scope {
    margin-bottom: 12px;
  }
}

.return {
  &__button-responsive {
    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
    background: $bgMid;
    box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
    //padding-top: 16px;
    position: relative;
    top: 80px;
    margin-left: -20px;
  }
}
.return__header-responsive {
  width: 90%;
  height: 40px;
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
  0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
  0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  .list-emission-return-responsive {
    height: 20px;
    padding-right: 8px;
      .return-active {
        display: none;
      }
    }
  &:hover {
      .list-emission-return-responsive {
        .return {
          display: none;
        }
        .return-active {
          display: block;
        }
      }
    }
  &:active {
      .list-emission-return-responsive {
        .return {
          display: block;
        }
        .return-active {
          display: none;
        }
      }
    }
}
.registration-responsive {
  width: 90%;
  margin: auto;
  padding: 16px 0;
  .common-btn  {
    width: 100%;
  }
}

.filter-organization {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}
.option-duration {
  display: flex;
  flex-flow: row-reverse !important;
  justify-content: space-between;
  gap: 16px;
}

@include desktop {
  .return__button-responsive {
    display: none;
  }
  .input-elic {
    /* width: 440px; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 16px;
    margin-top: 8px;
    position: static;
    width: 496px;
    height: 40px;
    left: 0;
    top: 32px;
    background: $monoWhite;
    box-sizing: border-box;
    box-shadow: inset 0 2px 2px rgb(160 181 186 / 15%), inset 0 2px 6px rgb(160 181 186 / 50%);
    border-radius: 4px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    outline: none;
  }
  .export-page {
    .listMenus-btn {
      display: flex;
      // flex-flow: row;
      flex-wrap: wrap;
      width: 100%;
      max-width: 560px;
      margin: auto;
      padding: 32px 32px 48px 32px;

      .listMenu-btn {
        // margin: 0 12px 24px 12px;
        display: flex;
        flex-flow: column;
        // align-items: center;
        justify-content: center;
        margin-top: 10px;
        padding: unset;

        &:hover {
          cursor: pointer;
        }

        img.listMenu-icon {
          display: flex;
          width: 20px;
          height: 36px;
          transform: rotate(90deg);
          flex: none;
          order: 0;
          flex-grow: 0;
        }

        .input-title {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0 16px;
          position: static;
          width: 496px !important;
          height: 40px;
          left: 0;
          top: 32px;
          background: $monoOffWhite;
          box-sizing: border-box;
          box-shadow: inset 0px 2px 2px rgb(160 181 186 / 15%), inset 0px 2px 6px rgb(160 181 186 / 50%);
          border-radius: 4px;
          flex: none;
          align-self: stretch;
          flex-grow: 0;
          margin: 8px 0px;
          outline: none;
        }

        .listMenu-btn-text {
          display: flex;
          flex-flow: column;
          // padding: 26px 0 22px;
          .listMenu-text {
            padding-left: 0;
          }
        }

        .listMenu-scope {
          /* Auto layout */
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 7px 16px 9px;

          width: 496px;
          height: 40px;

          /* Mono/OffWhite */

          background: $monoOffWhite;
          box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
          border-radius: 4px;

          /* Inside auto layout */
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
      }
    }

    .submit-register-btn {
      // background: $goldMid !important;
      box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
      border-radius: 4px;
      transition: 0.1s ease-out;
      .v-btn__content {
        color: white;
      }
    }

    .list-menu-title {
      height: 40px;
      margin-bottom: 48px;
      width: 100%;
      padding-left: 40px;

      .page-title {
        margin-bottom: 0;
      }
      .return__header {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 !important;
        width: 161px;
        .list-emission-return {
          height: 20px;
          .return-active {
            display: none;
          }
        }
        &:hover {
          .list-emission-return {
            .return {
              display: none;
            }
            .return-active {
              display: block;
            }
          }
        }
        &:active {
          .list-emission-return {
            .return {
              display: block;
            }
            .return-active {
              display: none;
            }
          }
        }
      }

    }

    .list-menu--title {
      padding-left: 40px;
    }

    .list-menu-registration {
      width: 100%;
      max-width: calc(560px);
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 32px;
      gap: 16px;
      height: 104px;
      background: $bgLight;
      border-top: 1px solid rgba(121, 134, 134, 0.12);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .common-btn {
        width: 100%;

        .v-btn__content {
          color: $monoWhite;
        }

      }
      &.has-encoding {
        height: 177px;
        flex-direction: column;
      }
    }
  }
}

.v-application a {
  color: rgba(0, 0, 0, 0.87);
}
</style>
